import React from "react";
import { Box, Typography } from "@mui/material";
import { Navigation, Autoplay } from "swiper/modules";
import { SwiperSlide } from "swiper/react";
import SwiperWrapper from "../SwiperWrapper/SwiperWrapper";
import { variables } from "../../theme/variables";
import RightImage from "../../assets/images/right.svg";
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from "axios";
import { Link } from "react-router-dom";
const StartReading = ({ topSellingBooks }) => {
  const matches = useMediaQuery("(max-width:900px)");
  const mobile = useMediaQuery("(max-width:500px)");
  const tablet = useMediaQuery("(max-width:1100px)");
  const desktop = useMediaQuery("(max-width:1250px)");

  const styles = {
    wrapper: {
      "& .swiper-wrapper": {
        padding: "0 50px 50px 50px",
      },
      // "& .swiper-slide": {
      //   width: "230px !important",
      // },
      "& .swiper-button-prev, .swiper-button-next": {
        backgroundColor: "#E4ACB414",
        borderRadius: "50%",
        width: "44px",
        color: "#fff",
      },
      "& .swiper-button-prev::after, .swiper-button-next::after": {
        fontSize: "10px",
        fontWeight: 900,
      },
      "@media only screen and (max-width: 800px)": {
        "& .swiper-button-prev, .swiper-button-next": {
          display: "none",
        },
      },
    },
  };

  const goToApp = async (id) => {
    axios
      .get(`/api/book/${id}/short-link`)
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  return (
    <>
      {topSellingBooks ? (
        <Box
          bgcolor={variables.black}
          className="md:px-20 py-[56px] md:py-[120px] mt-[-1px]"
        >
          <Box className="w-full pb-6 md:pb-12 ml-6 md:ml-0 flex justify-start md:justify-center">
            <Typography
              sx={{
                backgroundImage: variables.secondaryColor,
                WebkitBackgroundClip: "text",
                color: "transparent",
                display: "inline-block",
              }}
              variant="h3"
              fontWeight={500}
            >
              Start Reading
            </Typography>
          </Box>
          <Box sx={styles.wrapper}>
            <SwiperWrapper
              effect="slide"
              slidesPerView={
                mobile ? 1.9 : matches ? 2.7 : tablet ? 4 : desktop ? 5 : 5
              }
              spaceBetween={mobile ? 10 : 0}
              autoplay={3000}
              className="mySwiper"
              style={styles}
              loop={true}
              speed={500}
              navigation={true}
              modules={[Navigation, Autoplay]}
              delay={5000}
            >
              {topSellingBooks.map((item, key) => {
                return (
                  <SwiperSlide
                    onClick={() => window.fbq("track", "Click_book")}
                    key={key}
                    className="w-[180px] "
                  >
                    <Link to={item.shortLink} target="_blank">
                      <Box className="flex h-[460px] justify-between flex-col">
                        <Box>
                          <Box className="w-[180px] xl:w-[220px] h-[280px] xl:h-[330px]">
                            <img
                              className="w-full h-full object-cover rounded-2xl"
                              src={item.coverImage.url}
                              alt="cover"
                            />
                          </Box>
                          <Box className="max-w-[180px]">
                            <Typography
                              fontSize={16}
                              color={variables.primaryColor}
                              className="pt-4 pb-1 min-h-11 "
                              fontWeight={500}
                            >
                              {item.title}
                            </Typography>
                            <Typography
                              color={variables.darkGreyColor}
                              fontSize={15}
                              fontWeight={500}
                            >
                              {item.author.pen_name}
                            </Typography>
                            {item.status === 2 ? (
                              <Typography
                                fontSize={15}
                                className="py-3"
                                fontWeight={500}
                              >
                                $ {item.price}
                              </Typography>
                            ) : item.status === 6 ? (
                              <Typography
                                color={variables.textHover}
                                fontSize={15}
                                className="py-3"
                                fontWeight={500}
                              >
                                Free
                              </Typography>
                            ) : null}
                          </Box>
                        </Box>
                        <Box
                          onClick={() => goToApp(item.id)}
                          className="inline-flex items-center cursor-pointer  md:max-w-[100px]"
                        >
                          <Typography
                            color={"#437EF7"}
                            fontWeight={500}
                            fontSize={15}
                          >
                            Read Now
                          </Typography>
                          <img
                            src={RightImage}
                            alt="right-arrow"
                            className="ml-[6px]"
                          />
                        </Box>
                      </Box>
                    </Link>
                  </SwiperSlide>
                );
              })}
            </SwiperWrapper>
          </Box>
        </Box>
      ) : null}
    </>
  );
};

export default StartReading;
